import OptionConstants from '../../../constants/options';
import DisclaimerConstants from '../../../constants/disclaimer';
import { Carousel } from 'react-responsive-carousel';

let carouselContent = [
  {
    img: "/img/products/acolyteclip/1.jpeg",
    caption: 'The Acolyte style saber hanger, tucked under the accent belt.'
  },
  {
    img: "/img/products/acolyteclip/2.jpeg",
    caption: 'Even with no Lightsaber hanging, these clips still look great.'
  },
  {
    img: "/img/products/acolyteclip/3.jpeg",
    caption: 'Kit includes the leather strap, mounted Covertec, and hardware to mount the strap to your belt'
  },
  {
    img: "/img/products/acolyteclip/4.jpeg",
    caption: 'Jecki was the debut of this style for us, on her training uniform.'
  },
  {
    img: "/img/products/acolyteclip/5.jpeg",
    caption: 'Here you can see the screw-posts removed from the strap.'
  },
  {
    img: "/img/products/acolyteclip/6.jpeg",
    caption: 'Covertecs also come in black.'
  },
  {
    img: "/img/products/acolyteclip/7.jpeg",
    caption: 'Another color of strap with a black mounted covertec.  Check out our color matrix!'
  },
];

const Component = () => {
	return [
    <h2 className="title">Acolyte (High Republic) Covertec Saber Hanger</h2>,
    <p className="content">This genuine leather Covertec Lightsaber Hanger is hand made from veg tan'd leather and is 
    designed to securely fashion your lightsaber to your Jedi Belt. For lightsabers equip'd with a Covertec wheel, you 
    need a Covertec Clip on your belt to fasten them to.  This style of Covertec Clip is fashioned to mimic the High 
    Republic style seen in The Acolyte.
    <br /><br />
    This Covertec Clip is securely fastened to a strap of leather that goes under your accent, or small belt, and is 
    attached using two included screw-backed posts.  This means that you need to punch two holes in your main belt 
    where you want the strap to sit and use the included screw-backed posts to secure it.  You  never have to worry 
    about it falling off.  Protect that $500+ lightsaber at a convention, and trust in a mounted Covertec Clip.
    </p>,
    <div className="m-auto pt-3">
      <Carousel 
                autoPlay={true}
                swipable={true}
                stopOnHover={true}
                infiniteLoop={true}
                showThumbs={false}
                >
                  {
                    carouselContent.map(carouselItem => {
                      return <div className="picture-wrapper">
                        <img src={carouselItem.img} alt={carouselItem.caption} />
                        { carouselItem.caption ? <p className="legend">{carouselItem.caption}</p> : null }
                      </div>
                    })
                  }
              </Carousel>
    </div>,
    <p className="content more">{OptionConstants.more}</p>,
    <p className="content option">Pricing: The base price is $20.
      <ul>
        <li>Plastic Covertec plates are included for the base price.  Aluminum Covertec plates are $25 extra.</li>
        <li>Stitching around the edge of the leather can be added for $5 extra.</li>
        <li>Tooling around the edge of the leather can be added for $5 extra.</li>
      </ul>
    </p>,
    <p className="content">
    We offer a veriety of Covertec materials, Leather colors, tooling, and hardware.
    <br /><br />
    Covertec Materials : We offer both 3D Printed Plastic Covertec plates and also machined Aluminum Covertec plates.
    <br />
    The aluminum front plate is 1/8" and the rear plate is 0.80 aluminum CNC routed so the Covertec Wheel on your 
    lightsaber will slide right into the keyed slot and stay secure.  Aluminum Covertec Belt Clips made in collaboration 
    with <a href="https://www.instagram.com/C2_Models_and_Props/" rel="noreferrer" target="_blank">@C2_Models_and_Props</a> 
    &nbsp;on Instagram.
    <br /><br />
    <i><strong>Approval note: </strong>At this time, we haven't had any client get approval with this Covertec on 
    their belt.  That said, we have not heard of any rejections with it either.  "We must be cautious."</i>
    </p>,
    <p className="content">{DisclaimerConstants.handMade}</p>,
    <p className="content option">When ordering an Acolyte Covertec we will need to know several things, including:</p>,
    <p className="content option">1. { OptionConstants.covertecMaterialTypeAndColor }</p>,
    <p className="content option">2. { OptionConstants.color}</p>,
    <p className="content option">3. { OptionConstants.colorFinish}</p>,
    
    <p className="content option">5. { OptionConstants.hangerDecorations}</p>,
    <p className="content option">6. Hardware Color: Nickel (Shiny Silver), Bronze (Antiqued Brass), Gun Metal, Copper, Gold (Shiny Brass)</p>,
    <p className="content">
      Rebel Legion Status : <strong>Seeking Reference Photos</strong><br/>
      We haven't had any client get approval with this Covertec on their belt.<br />
      That said, we have not heard of any rejections with it either.  "We must be cautious."
    </p>,
    <p className="content disclaimer">{ DisclaimerConstants.vegTan }</p>,
    <p className="content disclaimer">{ DisclaimerConstants.mouseHouse }</p>,
  ];
};
  
  export default Component;