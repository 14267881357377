import OptionConstants from '../../../constants/options';
import DisclaimerConstants from '../../../constants/disclaimer';
import { Carousel } from 'react-responsive-carousel';

let carouselContent = [
  {
    img: "/img/products/kits/accessories/1.png",
    caption: "Black Gloss Cellphone Pouch on a Red Gloss OT Belt in Red."
  },
  {
    img: "/img/products/kits/accessories/2.png",
  },
  {
    img: "/img/products/kits/accessories/3.png",
  },
  // {
  //   img: "/img/products/kits/prequel/4.png",
  // },
  // {
  //   img: "/img/products/kits/prequel/5.png",
  //   caption: "Black Jedi Belt from a review on Etsy."
  // },
  // {
  //   img: "/img/products/kits/prequel/6.png",
  //   caption: "Jedi Belt with a Large pouch from a review on Etsy."
  // },
  // {
  //   img: "/img/products/kits/prequel/7.png",
  //   caption: "Jedi Belt with a Cell Phone pouch and a Covertec from a review on Etsy."
  // },
  // {
  //   img: "/img/products/kits/prequel/8.png",
  //   caption: "Jedi Belt with a Cell Phone pouch and a Covertec from a review on Etsy."
  // },
];

const Component = () => {
	return [
    <h2 className="title">Accessory Kit</h2>,
    <p className="content">The Accessory kit from Valley Green Leather is a set of hand made leather goods to 
      compliment a Star Wars belt you already have.  Whether you're looking for some pouches or saber hangers this
      kit will get you a commission slot to have accessories made for you.
      <br /><br />
      <strong>What is a kit, and how does it work?</strong>
      <br />
      The Accessory Kit's base price gets you a pouch and a reserved slot in our queue of 
      work.  Each week you will get an email with your place in that queue and our approximate start date for 
      your work.
      <br /><br />Two weeks before you are next we will be reaching out to you to firm up your kits details.  This 
      includes things like thread color, leather color, stamps, etc.  We will need your final details
      before we get started on your kit and failure to provide your details will push your project back in one week blocks.
    </p>,
    <div className="m-auto pt-3">
    <Carousel 
              autoPlay={true}
              swipable={true}
              stopOnHover={true}
              infiniteLoop={true}
              showThumbs={false}
              >
                {
                  carouselContent.map(carouselItem => {
                    return <div className="picture-wrapper">
                      <img src={carouselItem.img} alt={carouselItem.caption} />
                      { carouselItem.caption ? <p className="legend">{carouselItem.caption}</p> : null }
                    </div>
                  })
                }
            </Carousel>
  </div>,
  <p className="content more">{OptionConstants.more}</p>,
  <p className="content"><strong>How do I get one?</strong>
    <br /><br />
    At Valley Green Leather we sell the vast majority of our products through our&nbsp;
    <a href="https://valleygreenleather.etsy.com/" rel="noreferrer" target="_blank">Etsy Store</a>. Due to the 
    high demand of cosplayers upgrading their belt purchases to include pouches and saber hangers, we have decided to 
    change up how we sell our belts and offer a limited number of commission slots periodically through Etsy.
    <br /><br />
    <strong>The next time a batch of kits will be available on Etsy is: July 14th, 2023 at 1:00PM EST.</strong>
    <br /><br />
    When it is your turn, we will reach out to get the final details for your kit.  These details include: 
    <br /><br />
    <ol>
      <li>Kit Pouch Details - size, color, thread color, etc.</li>
      <li>
        <a href="/products/pouches/small" target="_blank">Small</a>,&nbsp;
        <a href="/products/pouches/wallet" target="_blank">Wallet</a>,&nbsp;
        <a href="/products/pouches/cellphone" rel="noreferrer" target="_blank">Cell Phone</a>&nbsp;or&nbsp;
        <a href="/products/pouches/large" target="_blank">Large</a>&nbsp;pouch add ons and their color, thread, and other details.
      </li>
      <li>Saber hangers for both&nbsp;
        <a href="/products/saberhangers/covertec" target="_blank">Covertec</a>&nbsp;and&nbsp;
        <a href="/products/saberhangers/dring" rel="noreferrer" target="_blank">D-Ring</a>&nbsp;
        equiped Lightsaber hilts.
      </li>
      <li>Mailing Address validation</li>
    </ol>
    Once your kits final details are known we will let you know the difference between the price of the kit and the 
    price of your combined items.  When your slot is next and we begin your kit we will ask for 50% of any outstanding
    balance, and then when its complete we will settle up with the final 50% and shipping.
    <br /><br />
    Throughout the process of building your kit we will update you with pictures and a description of where we are
    in the process.  At the very least we will send progress photos when your leather is cut and shaped, when the color is applied and 
    before we box it up and ship it off.  When the color is applied we ask you to approve the color before we apply 
    the top coat to it, as that is a point of no return.  Before we seal it we can add and remove some color to get it 
    to your liking.
  </p>,
  <p className="content option">Pricing: The base price for an Accessories Kit is $29.99.</p>,
  <p className="content disclaimer">{ DisclaimerConstants.vegTan }</p>,
  <p className="content disclaimer">{ DisclaimerConstants.mouseHouse }</p>,
  ];
};

export default Component;
