const Contact = () => {
  let cards = [
    {
      'title': 'Etsy Shop',
      'url': 'https://valleygreenleather.etsy.com/',
      'imgSvg': <svg enable-background="new 0 0 24 24" viewBox="0 0 24 24" class="sc-gKsewC hxdUPG"><title data-testid="svgTitle" id="title_0.7648465518134351">etsy</title><path d="M19.793,3s-.136,1.454-.3,4.713H18.157l-.119-.471C17.6,5.513,17.01,4.594,15.93,4.594H9.74c-.46,0-.486.161-.486.434l0,6.592c.009,0,.749.029,1.681.029,1.565,0,3.672-.081,3.777-.515l.379-1.568h1.163L16.1,12.283,16.256,15H15.093l-.379-1.568c-.105-.433-2.212-.515-3.777-.515-.931,0-1.669.029-1.68.03l0,4.92c0,1.163.649,1.7,2,1.7h4.027c1.352,0,2.568-.135,3.406-2.027l.547-1.255h1.347C20.4,17.8,20.1,20.3,20.037,21c0,0-3.7-.135-5.3-.135H7.658L3.413,21V19.865l1.433-.27c1-.189,1.325-.488,1.325-1.3,0,0,.08-1.729.08-6.189s-.08-6.216-.08-6.216c-.083-.919-.325-1.134-1.325-1.324L3.413,4.3V3.161L7.658,3.3h7.919c1.568,0,4.216-.3,4.216-.3m.794,13.287h0M19.793,2a1.032,1.032,0,0,0-.111.006c-.026,0-2.612.29-4.1.29H7.658L3.445,2.162H3.413a1,1,0,0,0-1,1V4.3a1,1,0,0,0,.815.983l1.433.27a2.752,2.752,0,0,1,.467.117,2.071,2.071,0,0,1,.047.314h0s.076,1.783.076,6.126c0,4.373-.079,6.126-.079,6.143a1.794,1.794,0,0,1-.013.224,2.12,2.12,0,0,1-.5.138l-1.432.27a1,1,0,0,0-.815.982V21a1,1,0,0,0,1,1h.032l4.245-.135h7.048c1.56,0,5.226.133,5.263.134h.037a1,1,0,0,0,1-.918c.056-.681.349-3.121.536-4.6a1.059,1.059,0,0,0,.019-.2,1,1,0,0,0-1-1H19.24a1,1,0,0,0-.917.6l-.547,1.255c-.572,1.292-1.2,1.427-2.489,1.427H11.26c-1,0-1-.28-1-.7l0-3.945c.207,0,.437,0,.68,0a16.364,16.364,0,0,1,2.907.171l.277,1.147a1,1,0,0,0,.972.765h1.163a1,1,0,0,0,1-1.059L17.1,12.283l.157-2.658a1,1,0,0,0-1-1.059H15.093a1,1,0,0,0-.972.765l-.277,1.148a16.523,16.523,0,0,1-2.907.17c-.244,0-.474,0-.681,0V5.6l5.675,0c.181,0,.664,0,1.138,1.892l.12.474a1,1,0,0,0,.969.753h1.332a1,1,0,0,0,1-.948c.165-3.193.3-4.658.3-4.672a1,1,0,0,0-1-1.093Zm0,2h0Z"></path></svg>
    },
    {
      'title': 'Facebook',
      'url': 'https://www.facebook.com/valleygreenleather',
      'imgSvg': <svg enable-background="new 0 0 24 24" viewBox="0 0 24 24" class="sc-gKsewC hxdUPG"><title data-testid="svgTitle" id="title_0.13648361895077255">facebook</title><path d="M23,12A11,11,0,1,0,10.279,22.865h0a11.08,11.08,0,0,0,3.436,0h0A10.952,10.952,0,0,0,23,12ZM10.859,21.935v-6.9a.5.5,0,0,0-.5-.5H8.193V12.5h2.166a.5.5,0,0,0,.5-.5V9.686c0-2.278,1.264-3.585,3.459-3.585a15.392,15.392,0,0,1,1.858.137V7.89h-.824l-.019,0a2,2,0,0,0-2.181,1.735,1.8,1.8,0,0,0-.011.4V12a.5.5,0,0,0,.5.5H15.97l-.312,2.035H13.641a.5.5,0,0,0-.5.5v6.9A10.124,10.124,0,0,1,10.859,21.935Zm3.282-.166V15.535h1.946a.5.5,0,0,0,.5-.425l.465-3.035a.5.5,0,0,0-.494-.575H14.141V10.016a1.267,1.267,0,0,1,.308-.821,1.218,1.218,0,0,1,.9-.3h1.324a.5.5,0,0,0,.5-.5V5.806a.5.5,0,0,0-.42-.494A16.661,16.661,0,0,0,14.325,5.1c-2.754,0-4.466,1.757-4.466,4.585V11.5H7.693a.5.5,0,0,0-.5.5v3.035a.5.5,0,0,0,.5.5H9.859v6.234a10,10,0,1,1,4.282,0Z"></path></svg>,
    },
    {
      'title': 'Instagram',
      'url': 'https://www.instagram.com/valleygreenleather',
      'imgSvg': <svg enable-background="new 0 0 24 24" viewBox="0 0 24 24" class="sc-gKsewC hxdUPG"><title data-testid="svgTitle" id="title_0.13648361895077255">facebook</title><path d="M23,12A11,11,0,1,0,10.279,22.865h0a11.08,11.08,0,0,0,3.436,0h0A10.952,10.952,0,0,0,23,12ZM10.859,21.935v-6.9a.5.5,0,0,0-.5-.5H8.193V12.5h2.166a.5.5,0,0,0,.5-.5V9.686c0-2.278,1.264-3.585,3.459-3.585a15.392,15.392,0,0,1,1.858.137V7.89h-.824l-.019,0a2,2,0,0,0-2.181,1.735,1.8,1.8,0,0,0-.011.4V12a.5.5,0,0,0,.5.5H15.97l-.312,2.035H13.641a.5.5,0,0,0-.5.5v6.9A10.124,10.124,0,0,1,10.859,21.935Zm3.282-.166V15.535h1.946a.5.5,0,0,0,.5-.425l.465-3.035a.5.5,0,0,0-.494-.575H14.141V10.016a1.267,1.267,0,0,1,.308-.821,1.218,1.218,0,0,1,.9-.3h1.324a.5.5,0,0,0,.5-.5V5.806a.5.5,0,0,0-.42-.494A16.661,16.661,0,0,0,14.325,5.1c-2.754,0-4.466,1.757-4.466,4.585V11.5H7.693a.5.5,0,0,0-.5.5v3.035a.5.5,0,0,0,.5.5H9.859v6.234a10,10,0,1,1,4.282,0Z"></path></svg>,
    },
    {
      'title': 'Email',
      'url': 'mailto:valleygreenleather@gmail.com',
      'imgSvg': <svg enable-background="new 0 0 24 24" viewBox="0 0 24 24" class="sc-gKsewC hxdUPG"><title data-testid="svgTitle" id="title_0.4051364688274244">email</title><path d="M18.821,20.5H5.179A3.683,3.683,0,0,1,1.5,16.821V7.179A3.683,3.683,0,0,1,5.179,3.5H18.821A3.683,3.683,0,0,1,22.5,7.179v9.642A3.683,3.683,0,0,1,18.821,20.5ZM5.179,4.5A2.682,2.682,0,0,0,2.5,7.179v9.642A2.682,2.682,0,0,0,5.179,19.5H18.821A2.682,2.682,0,0,0,21.5,16.821V7.179A2.682,2.682,0,0,0,18.821,4.5Z"></path><path d="M12,14.209a.5.5,0,0,1-.346-.138L4.286,7.028a.5.5,0,0,1,.691-.723L12,13.018l7.023-6.713a.5.5,0,1,1,.691.723l-7.368,7.043A.5.5,0,0,1,12,14.209Z"></path><path d="M4.7,17.833a.5.5,0,0,1-.347-.86l5.54-5.31a.5.5,0,0,1,.692.722L5.048,17.694A.5.5,0,0,1,4.7,17.833Z"></path><path d="M19.3,17.832a.5.5,0,0,1-.346-.139l-5.538-5.308a.5.5,0,0,1,.692-.722l5.538,5.308a.5.5,0,0,1-.346.861Z"></path></svg>,
    },
    {
      'title': 'Paypal',
      'url': 'https://paypal.me/DrewMcLain',
      'imgSvg': <svg enable-background="new 0 0 24 24" viewBox="0 0 24 24" class="sc-gKsewC hxdUPG"><title data-testid="svgTitle" id="title_0.20747835254385127">payment</title><path d="M12,1A11,11,0,1,0,23,12,11.012,11.012,0,0,0,12,1Zm0,21A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z"></path><path d="M12.5,11.317V7.545c2.185.275,2.288,1.863,2.292,2.062a.5.5,0,0,0,1,0A3.277,3.277,0,0,0,12.5,6.534V5.5a.5.5,0,0,0-1,0V6.523A3.612,3.612,0,0,0,8.857,7.578,2.826,2.826,0,0,0,8.2,9.629c0,.085.14,1.993,3.305,2.549v4.3a2.551,2.551,0,0,1-2.614-2.562.5.5,0,0,0-1,0,3.582,3.582,0,0,0,3.614,3.54V18.5a.5.5,0,0,0,1,0V17.459c2.822-.208,3.614-2.095,3.614-3.131C16.114,13.051,15.661,11.81,12.5,11.317ZM9.19,9.541a1.841,1.841,0,0,1,.418-1.3A2.632,2.632,0,0,1,11.5,7.521V11.16C9.338,10.725,9.2,9.618,9.19,9.541Zm3.31,6.938V12.328c2.609.434,2.614,1.334,2.614,2C15.114,14.412,15.08,16.268,12.5,16.479Z"></path></svg>,
    },
  ];

  let cardJSX = <div className="contactCards">
    { cards.map(card => {
        return <div className="contactCard" onClick={() => { 
            window.open(card.url, "_blank"); 
          }}>
            <div className="img d-flex justify-content-center">{card.imgSvg}</div>
            <div className="title d-flex justify-content-center">{card.title}</div>
          </div>
    }) }
  </div>

	return [
    <h2 className="title">Contact</h2>,
    <p className="content">Want to place an order, ask a question or see more of our work?<br /><br />Want to buy me a Starbees?  
    <br /><br />Check out the links below to contact us, we'd love to hear from you.</p>,
    cardJSX
  ]};
  
  export default Contact;
