import OptionConstants from '../../../constants/options';
import DisclaimerConstants from '../../../constants/disclaimer';
import { Carousel } from 'react-responsive-carousel';

let carouselContent = [
  {
    img: "/img/products/covertec/1.png",
    caption: 'Black, Matte Finish, Aluminum Covertec with Nickle hardware.'
  },
  {
    img: "/img/products/covertec/2.png",
    caption: "Side view, it slips on your belt."
  },
  {
    img: "/img/products/covertec/3.png",
    caption: "Back of the Covertec has the Valley Green Leather Makers Mark."
  },
  {
    img: "/img/products/covertec/4.png",
    caption: "AG-7,2 Matte Finish, Aluminum Covertec with Antique Brass hardware."
  },
  {
    img: "/img/products/covertec/5.png",
    caption: "AG-7,2 Gloss Finish, Black Plastic Covertec with Antique Brass hardware."
  },
  {
    img: "/img/products/covertec/6.png",
    caption: "AG-2,3 Gloss Finish, Brown Plastic Covertec with Nickle hardware."
  },
  {
    img: "/img/products/covertec/7.png",
    caption: "Black, Matte Finish, Tooling #3, Red Stitching, Angled with Black Plastic Covertec and Nickle Hardware."
  },
  {
    img: "/img/products/covertec/8.png",
    caption: "Looks great with a saber hanging from it!",
  },
  {
    img: "/img/products/covertec/9.png",
    caption: "AG-9,2 Matte Finish, Aluminum Covertec with Antique Brass hardware."
  },
  {
    img: "/img/products/covertec/10.png",
    caption: "AG-2,3 Matte Finish, Tooling #8, Brown Stitching, Black Plastic Covertec with Antique Brass hardware."
  },
];

const Component = () => {
	return [
    <h2 className="title">Covertec Saber Hanger</h2>,
    <p className="content">This genuine leather Covertec Lightsaber Hanger is hand made from veg tan'd leather and is 
    designed to securely fashion your lightsaber to your Jedi Belt. For lightsabers equip'd with a Covertec wheel, you 
    need a Covertec Clip on your belt to fasten them to.
    <br /><br />
    Covertec clips from cell phones in the 90's were used in the movies, and were just "Clipped" onto their belts.  
    These clips weren't large enough to work effectively as they are designed for 1.5" mens belts.  The simple act of 
    walking down stairs can cause the screen accurate ones to fall right off of your belt!
    <br /><br />
    This Covertec Saber Hanger, however, is securely fastened to a loop of leather that slides onto your belt.  You can 
    easily adjust it's position and never have to worry about it falling off. Protect that $500+ lightsaber at a 
    convention, and trust in a mounted Covertec.
    </p>,
    <div className="m-auto pt-3">
      <Carousel 
                autoPlay={true}
                swipable={true}
                stopOnHover={true}
                infiniteLoop={true}
                showThumbs={false}
                >
                  {
                    carouselContent.map(carouselItem => {
                      return <div className="picture-wrapper">
                        <img src={carouselItem.img} alt={carouselItem.caption} />
                        { carouselItem.caption ? <p className="legend">{carouselItem.caption}</p> : null }
                      </div>
                    })
                  }
              </Carousel>
    </div>,
    <p className="content more">{OptionConstants.more}</p>,
    <p className="content option">Pricing: The base price is $24.99.
      <ul>
        <li>Plastic Covertec plates are included for the base price.  Aluminum Covertec plates are $25 extra.</li>
        <li>Stitching around the edge of the leather can be added for $5 extra.</li>
        <li>Tooling around the edge of the leather can be added for $5 extra.</li>
      </ul>
    </p>,
    <p className="content">
    We offer a veriety of Covertec materials, Leather colors, tooling, and hardware.
    <br /><br />
    Covertec Materials : We offer both 3D Printed Plastic Covertec plates and also machined Aluminum Covertec plates.
    <br />
    The aluminum front plate is 1/8" and the rear plate is 0.80 aluminum CNC routed so the Covertec Wheel on your 
    lightsaber will slide right into the keyed slot and stay secure.  Aluminum Covertec Belt Clips made in collaboration 
    with <a href="https://www.instagram.com/C2_Models_and_Props/" rel="noreferrer" target="_blank">@C2_Models_and_Props</a> 
    &nbsp;on Instagram.
    <br /><br />
    <i><strong>Approval note: </strong>At this time, we haven't had any client get approval with this Covertec on 
    their belt.  That said, we have not heard of any rejections with it either.  "We must be cautious."</i>
    </p>,
    <p className="content">{DisclaimerConstants.handMade}</p>,
    <p className="content option">When ordering a Covertec we will need to know several things, including:</p>,
    <p className="content option">1. { OptionConstants.hangerSize }
    	<div className="product-video covertec">
				<video controls aria-label="Product video">
					<source src="/video/covertec.mp4" type="video/mp4" />
				</video>
			</div>
    </p>,
    <p className="content option">2. { OptionConstants.color}</p>,
    <p className="content option">3. { OptionConstants.colorFinish}</p>,
    <p className="content option">4. { OptionConstants.hangerShape}</p>,
    <p className="content option">5. { OptionConstants.hangerDecorations}</p>,
    <p className="content option">6. Hardware Color: Nickel (Shiny Silver), Bronze (Antiqued Brass), Gun Metal, Copper, Gold (Shiny Brass)</p>,
    <p className="content">
      Rebel Legion Status : <strong>Seeking Reference Photos</strong><br/>
      We haven't had any client get approval with this Covertec on their belt.<br />
      That said, we have not heard of any rejections with it either.  "We must be cautious."
    </p>,
    <p className="content disclaimer">{ DisclaimerConstants.vegTan }</p>,
    <p className="content disclaimer">{ DisclaimerConstants.mouseHouse }</p>,
  ];
};
  
  export default Component;