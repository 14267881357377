import OptionConstants from '../../../constants/options';
import DisclaimerConstants from '../../../constants/disclaimer';
import { Carousel } from 'react-responsive-carousel';

let carouselContent = [
  {
    img: "/img/products/dring/1.png",
    caption: 'Black, Glossy, Tooling #1, Gun Metal hardware, E1 Rivets with Saber and Angled Bottom'
  },
  {
    img: "/img/products/dring/2.png",
    caption: 'Black, Glossy, Tooling #1, Gun Metal hardware, E1 Rivets and Angled Bottom - Front'
  },
  {
    img: "/img/products/dring/3.png",
    caption: 'Black, Glossy, Tooling #1, Gun Metal hardware, E1 Rivets and Angled Bottom - Side'
  },
  {
    img: "/img/products/dring/4.png",
    caption: 'Black, Glossy, Tooling #1, Gun Metal hardware, E1 Rivets and Angled Bottom - Back'
  },
  {
    img: "/img/products/dring/5.png",
    caption: 'AG-3,2 Wide, AG-6,3 Accent, Glossy, Tooling #7, Bronze hardware, D2 Rivets and Angled Bottom'
  },
  {
    img: "/img/products/dring/6.png",
    caption: 'AG-8,2, Matte, Tooling #20, Nickel hardware, A2 Rivets, Stitching with Brown Thread and Rounded Bottom'
  },
  {
    img: "/img/products/dring/7.png",
    caption: 'AG-11,2, Matte, Tooling #7, Nickel hardware, E3 Rivets and Angled Bottom'
  },
  {
    img: "/img/products/dring/8.png",
    caption: 'Black, Matte, Tooling #X, Bronze hardware, E3 Rivets and Squared Bottom'
  },
  {
    img: "/img/products/dring/9.png",
    caption: 'Black Wide, Red Accent, Matte, Tooling #7, Nickel hardware, E3 Rivets, Angled Bottom, Red Stitching'
  },
  {
    img: "/img/products/dring/10.png",
    caption: 'Description of the parts of a D-Ring Saber Hanger.'
  },
];

const Component = () => {
	return [
    <h2 className="title">D-Ring Saber Hanger</h2>,
    <p className="content">This genuine leather belt clip is 
    designed to securely fashion your lightsaber to your Jedi Belt.  For lightsabers equip'd with 
    D-Rings, you need a hook on your belt to fasten them to.  Most (cannon, Rebel Legion approvable) 
    hooks require you to punch holes in your belt to fasten them, which makes them fixed in place, and 
    restricted to one side.  The other option, Covertec Wheels, won't help you out if you have a D-ring, 
    and while cannon, seem like "forced" technology in the Star Wars universe.
    <br /><br />
    This clip, however, moves around on your belt as you see fit - left side, right side, around on your 
    back, etc.  If you take a $500+ lightsaber to a convention, are you going to trust it to a simple 
    hook on your belt?  This clip closes around your D-Ring, easily, and will hold it in place, on your 
    belt, until you remove it.
    </p>,
    <div className="m-auto pt-3">
      <Carousel 
                autoPlay={true}
                swipable={true}
                stopOnHover={true}
                infiniteLoop={true}
                showThumbs={false}
                >
                  {
                    carouselContent.map(carouselItem => {
                      return <div className="picture-wrapper">
                        <img src={carouselItem.img} alt={carouselItem.caption} />
                        { carouselItem.caption ? <p className="legend">{carouselItem.caption}</p> : null }
                      </div>
                    })
                  }
              </Carousel>
    </div>,
    <p className="content more">{OptionConstants.more}</p>,
    <p className="content">
      They are great for everyday use as well - for keys, a flashlight, a water bottle, or your wallet chain.
      <br /><br />
      There are three pieces of leather that make up this Saber Clip - The "Wide" piece that is the main 
      body of the item with everything riveted onto it, the "Accent" piece that is the small loop of leather 
      that holds the clip in place, and the "Crossover" piece that rivets the clip hardware to the wide 
      piece.
      <br /><br />
      <i><strong>Approval note: </strong>At this time, we haven't had any client get approval with this D-Ring on 
      their belt.  That said, we have not heard of any rejections with it either.  "We must be cautious."</i>
    </p>,
    <p className="content">{DisclaimerConstants.handMade}</p>,
    <p className="content option">Pricing: The base price is $24.99.
      <ul>
        <li>Stitching around the edge of the leather can be added for $5 extra.</li>
        <li>Tooling around the edge of the leather can be added for $5 extra.</li>
      </ul>
    </p>,
    <p className="content option">When ordering a D-Ring we will need to know several things, including:</p>,
    <p className="content option">1. { OptionConstants.hangerSize }
      <div className="product-video dring">
				<video controls aria-label="Product video">
					<source src="/video/dring.mp4" type="video/mp4" />
				</video>
			</div>
    </p>,
    <p className="content option">2. { OptionConstants.color}</p>,
    <p className="content option">3. { OptionConstants.colorFinish}</p>,
    <p className="content option">4. { OptionConstants.hangerShape}</p>,
    <p className="content option">5. { OptionConstants.hangerDecorations}</p>,
    <p className="content option">6. Do you want the Wide piece and the Accent piece to be the same color? If not - what color would you like the accent piece to be?</p>,
    <p className="content option">7. Which tooling option would you like your Accent piece to have?
      <br /><br /><i><strong>Approval note (10):</strong> If you're going for costume approval with the Rebel Legion or Saber Guild
      #1 is your safest bet.</i>
      <br /><br />
      See the chart below:
      <br /><br />
      <div className="picture-wrapper">
        <img src="/img/products/options/tooling.jpg" alt="Tooling Options" className="link" onClick={ () => { 
        window.open ('/img/products/options/tooling.jpg', '_blank'); }}/>
      </div>
    </p>,
    <p className="content option">8. Clip Hardware Color: Nickel (Shiny Silver), Bronze (Antiqued Brass), Gun Metal, Copper, Gold (Shiny Brass)</p>,
    <p className="content option">9. Rivets - There are rivets that hold the accent belt down, and hold the leather loop closed.<br /><br />
      {OptionConstants.rivetColorBody}</p>,
    <p className="content">
      Rebel Legion Status : <strong>Seeking Reference Photos</strong><br/>
      We haven't had any client get approval with this Covertec on their belt.<br />
      That said, we have not heard of any rejections with it either.  "We must be cautious."
    </p>,
    <p className="content disclaimer">{ DisclaimerConstants.vegTan }</p>,
    <p className="content disclaimer">{ DisclaimerConstants.mouseHouse }</p>,
  ];
};
  
  export default Component;