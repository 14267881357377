import OptionConstants from '../../../constants/options';
import DisclaimerConstants from '../../../constants/disclaimer';
import { Carousel } from 'react-responsive-carousel';

let carouselContent = [
  {
    img: "/img/products/prequel/1.png",
  },
  {
    img: "/img/products/prequel/2.png",
  },
  {
    img: "/img/products/prequel/3.png",
  },
  {
    img: "/img/products/prequel/4.png",
    // caption: "@TODO Write this caption."
  },
  {
    img: "/img/products/prequel/5.png",
    // caption: "@TODO Write this caption."
  },
  {
    img: "/img/products/prequel/6.png",
    // caption: "@TODO Write this caption."
  },
  {
    img: "/img/products/prequel/7.png",
    // caption: "@TODO Write this caption."
  },
  {
    img: "/img/products/prequel/8.png",
    // caption: "@TODO Write this caption."
  },
  {
    img: "/img/products/prequel/9.png",
    // caption: "@TODO Write this caption."
  },
  {
    img: "/img/products/prequel/10.png",
    // caption: "@TODO Write this caption."
  },
  {
    img: "/img/products/prequel/11.png",
    caption: "Rear of the belt, 'Keeper' covering exposed velcro - Rivets out."
  },
  {
    img: "/img/products/prequel/12.png",
    caption: "Rear of the belt, 'Keeper' covering exposed velcro - Rivets in."
  },
];

const Component = () => {
	return [
    <h2 className="title">Prequel Jedi Belt</h2>,
    <p className="content">A replica of the leather belts worn by many Jedi in the Star Wars Prequel movies, this style
      of belt is often referred to as a Prequel Belt.  These were worn by Obi Wan Kenobi, Anakin Skywalker, Mace Windu, 
      etc. in the films and on countless Jedi in the Battle of Geonosis as well as in the Clone Wars series, 
      Star Wars: Visions and the High Republic book and comic series.
      <br /><br />
      We offer a veriety of widths, buckles, colors, tooling, rivets, and finishes.
      <br /><br />
      For adults, belts will be 2.5, or 3" wide, and 60" long maximum.  All belts will have 5" of overlapping velcro 
      in the back adjustability.  For children a 2" wide variation is available, built to allow the belt to expand up to 8" as your child grows (out of the belt...).
    </p>,
    <p className="content">{DisclaimerConstants.handMade}</p>,
    <div className="m-auto pt-3">
      <Carousel 
                autoPlay={true}
                swipable={true}
                stopOnHover={true}
                infiniteLoop={true}
                showThumbs={false}
                >
                  {
                    carouselContent.map(carouselItem => {
                      return <div className="picture-wrapper">
                        <img src={carouselItem.img} alt={carouselItem.caption} />
                        { carouselItem.caption ? <p className="legend">{carouselItem.caption}</p> : null }
                      </div>
                    })
                  }
              </Carousel>
    </div>,
    <p className="content more">{OptionConstants.more}</p>,
    <p className="content option">Pricing: The base price is $94.99.
      <ul>
      <li>Buckles Ni1, Ni8, BB1, and GM1 are included.</li>
      <li>Buckles Ni2, Ni3, Ni6, Ni9, BB4 and BL1 are $25 extra.</li>
      <li>All other Buckles are $15 Extra.</li>
      <li>Wide and Narrow Saber Clips are $15.</li>
      <li>For lengths over 48" long, it will be $10 extra.
      </li>
      </ul>
    </p>,
    <p className="content option">
      When ordering a Prequel Belt we will need to know several things, including:</p>,
    <p className="content option">1. {OptionConstants.size}<br /><br />
      We use 5" of velcro, with the center of the velcro at the measurement you give us, so it will give you 
      about 3.5" of play each direction. We will include a sleeve of leather (the keeper) to slide over the velcro 
      overlap in the back to hide it and "keep" your velcro secured together.  See the last two images above for keeper 
      examples, they can be worn rivets in, or rivets out.

      <br /><br />
      <div className="product-video sizing">
        <video controls aria-label="Sizing video">
          <source src="/video/sizing.mp4" type="video/mp4" />
        </video>
      </div>
      <br />
    </p>,
    <p className="content option">2. Width: How wide do you want it to be?  Adjult style 2.5 or 3" wide or Childrens style 2" wide.</p>,
    <p className="content option">3. { OptionConstants.color}</p>,
    <p className="content option">4. { OptionConstants.colorFinish}</p>,
    <p className="content option">5. Do you want the main belt and the accent (small) belt to be the same color?  
      If not - what color would you like the accent (small) belt to be?
      <br /><br /><i><strong>Approval note (10): </strong>We have had clients get approved with matching accent and main belt, but 
      have no reports either way on two toned belts.</i>
    </p>,
    <p className="content option">6. Which tooling option would you like your accent belt to have?
      <br /><br /><i><strong>Approval note (10):</strong> If you're going for costume approval with the Rebel Legion or Saber Guild
      #1 is your safest bet, however, clients have gotten approval with #1, 3, and 13.</i>
      <br /><br />
      See the chart below:
      <br /><br />
      <div className="picture-wrapper">
        <img src="/img/products/options/tooling.jpg" alt="Tooling Options" className="link" onClick={ () => { 
        window.open ('/img/products/options/tooling.jpg', '_blank'); }}/>
      </div>
    </p>,
    <p className="content option">7. Which accent belt buckle would you like?
      <br /><ul>
        <li>Buckles Ni1, Ni8, BB1, and GM1 are included.</li>
        <li>Buckles Ni2, Ni3, Ni6, Ni9, BB4 and BL1 are $25 extra.</li>
        <li>All other Buckles are $15 Extra.</li>
      </ul>
      See the chart below:<br /><br />
      <div className="picture-wrapper">
        <img src="/img/products/options/pj-buckle.jpg" alt="Buckle Options" className="link" onClick={ () => { 
          window.open ('/img/products/options/pj-buckle.jpg', '_blank'); }} />
      </div>
    </p>,
    <p className="content option">8. Rivets - There are rivets to the left and right of the accent belt buckle and 
      also on the keeper on the back of the belt.<br /><br />
      {OptionConstants.rivetColorBody}</p>,
    <p className="content option">9. Rivet Treatment - In the picture below you can see that the top belt has its 
      rivets (on the left and right of the accent belt) exposed, where the bottom two have them wrapped.  These rivets 
      really help the accent belt stay in place and prevent sagging. All of our Prequel Belts have a riveted accent.  
      Do you want the rivets by the buckle exposed or leather wrapped around them so they look more like traditionally 
      "keeper'd" sections?
      <br /><br /><strong>Approval note (10):</strong> We have had clients try and fail to be approved with exposed rivets.
      <br /><br />
      <div className="picture-wrapper">
        <img src="/img/products/prequel/1.png" alt="Belts" className="link" onClick={ () => { 
          window.open ('/img/products/prequel/1.png', '_blank'); }} />
      </div>
      <br /><br />
    </p>,
    <p className="content option">10. <strong>Going for approval?</strong>  If you are planning to use this belt as 
      part of an application for costume approval in the Rebel Legion, Saber Guild, or other standards based costuming 
      group, please let us know so we can try and help!  We are not judges, or directly affiliated with any groups.  
      If you are seeking approval it would be best to ask a judge, sponsor, etc. to look over your options here before
      ordering just to double check them before committing to a purchase.</p>,
    <p className="content option">11. { OptionConstants.saberClips }</p>,
    <p className="content">
      Rebel Legion Status : <strong>Approvable!</strong><br/>
      Clients who purchased this belt have gotten Rebel Legion approval in costumes which included this belt with Tooling 1, 3, and 13.<br/>
      Clients who purchased this belt with exposed rivets have gotten rejected, and were required to wrap their rivets by the buckle so we recommend going with leather wrapped rivets when you order if you're going for approval.<br/>
      Clients who purchased this belt have gotten Rebel Legion approval in costumes which included this belt where the main belt and accent matched in color.<br/>
    </p>,
    <p className="content disclaimer">{ DisclaimerConstants.vegTan }</p>,
    <p className="content disclaimer">{ DisclaimerConstants.mouseHouse }</p>,
    
  ];
};
  
  export default Component;