import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './pages/layout.js';
import Home from './pages/home.js';
import Pouches from './pages/products/pouches.js';
import PouchSmall from './pages/products/pouches/small.js';
import PouchWallet from './pages/products/pouches/wallet.js';
import PouchLarge from './pages/products/pouches/large.js';
import PouchCellPhone from './pages/products/pouches/cellPhone.js';
import SaberHangers from './pages/products/saberHangers.js';
import SaberHangerAcolyteCovertec from './pages/products/saberHangers/acolyteclip.js';
import SaberHangerCovertec from './pages/products/saberHangers/covertec.js';
import SaberHangerDRing from './pages/products/saberHangers/dRing.js';
import Belts from './pages/products/belts.js';
import BeltOriginal from './pages/products/belts/original.js';
import BeltPrequel from './pages/products/belts/prequel.js';
import Kits from './pages/products/kits.js';
import KitOriginal from './pages/products/kits/original.js';
import KitPrequel from './pages/products/kits/prequel.js';
import KitAccessory from './pages/products/kits/accessory.js';
import FaceCharacters from './pages/products/faceCharacters.js';
import FaceCharacterANHObiWan from './pages/products/faceCharacters/anhObiWan.js';
import FaceCharacterNihilus from './pages/products/faceCharacters/nihilus.js';
import FaceCharacterROTJLuke from './pages/products/faceCharacters/rotjLuke.js';
import GuitarStrapPrequel from './pages/products/guitarstrap/prequel.js';

import ContactUs from './pages/contact.js';
import NoPage from './pages/noPage.js';
import reportWebVitals from './reportWebVitals';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="products/pouches" element={<Pouches />} />
          <Route path="products/pouches/small" element={<PouchSmall />} />
          <Route path="products/pouches/wallet" element={<PouchWallet />} />
          <Route path="products/pouches/large" element={<PouchLarge />} />
          <Route path="products/pouches/cellphone" element={<PouchCellPhone />} />
          <Route path="products/saberhangers" element={<SaberHangers />} />
          <Route path="products/saberhangers/acolyteclip" element={<SaberHangerAcolyteCovertec />} />
          <Route path="products/saberhangers/covertec" element={<SaberHangerCovertec />} />
          <Route path="products/saberhangers/dring" element={<SaberHangerDRing />} />
          <Route path="products/belts" element={<Belts />} />
          <Route path="products/belts/original" element={<BeltOriginal />} />
          <Route path="products/belts/prequel" element={<BeltPrequel />} />
          <Route path="products/kits" element={<Kits />} />
          <Route path="products/kits/original" element={<KitOriginal />} />
          <Route path="products/kits/prequel" element={<KitPrequel />} />
          <Route path="products/kits/accessory" element={<KitAccessory />} />
          <Route path="products/faceCharacters" element={<FaceCharacters />} />
          <Route path="products/faceCharacters/anhobiwan" element={<FaceCharacterANHObiWan />} />
          <Route path="products/faceCharacters/nihilus" element={<FaceCharacterNihilus />} />
          <Route path="products/faceCharacters/rotjluke" element={<FaceCharacterROTJLuke />} />
          <Route path="products/guitarstrap/prequel" element={<GuitarStrapPrequel />} />
          <Route path="contact" element={<ContactUs />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
