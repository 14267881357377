const options = {
	size: <span>Length - How long should it be?<br /><br />
		When you choose your length, be sure you measure where you want it to sit on your body, ideally with your 
		costume on.  If you don't yet have your costume, a hooded sweatshirt is often accurate.
		<br /><br />
		Unfortunately your pants size or retail belt size isn't a good judge because some folks carry weight around 
		their belly button/mid section where the belt sits (like me) and some don't.  <strong>Everyone in the galaxy is unique!</strong>
		<br /><br />{/* @TODO Add a picture of where to measure/how to measure. **/}
		Keep this in mind when you choose your length.
		<br /><br />
      	Lengths over 48" long will be $10 extra.</span>,
	more: <span>Check out <a href="https://www.instagram.com/valleygreenleather/" rel="noreferrer" 
		target="_blank">our Instagram</a> or&nbsp;
		<a href="https://www.facebook.com/valleygreenleather" target="_blank" rel="noreferrer">Facebook</a> for more!</span>,
	color: <span className="color">Color - Which color would you like?<br /><br />
		We offer black, and many shades of brown. We refer to these by their column and row indicators; so the first row, 
		left side is AG-1, and the second tile in on that row would be AG-1,2.  All of the Antique Gel's all have the 
		accented, almost wood-grained weathered look. 
		<br /><br />
		<strong>** Note: Black is NOT pictured on the color chart. **</strong>
		<br /><br />
		<div className="picture-wrapper">
			<img src="/img/products/options/colors.jpg" alt="Color Options" className="link" onClick={ () => { 
				window.open ('/img/products/options/colors.jpg', '_blank'); }}/>
		</div>
		<br /></span>,
	covertecMaterialTypeAndColor: <span className="covertecMaterialTypeAndColor">Covertec Clip - Material and Color - Which one would you like?<br /><br />
		You may choose either an Aluminum Covertec Clip, or from two colors of 3D Printed Plastic - Black or Brown.
		The Aluminum Clips carry a $25 charge.
		</span>,
	colorFinish: <span className="finish">Finish - Matte or Gloss?  Check out this video to see them compared:
		<br /><br />
		<div className="product-video finish">
			<video controls aria-label="Product video">
				<source src="/video/finish.mp4" type="video/mp4" />
			</video>
		</div>
		<br />
		Both finishes will protect the leather from water damage, and keep the antique gel where it belongs - on your 
		leather, not on your clothes or skin!</span>,
	rivetColorBody: <span>Please see the Photo below to make a selection for your rivets:
		<br /><br />
		<div className="picture-wrapper">
			<img src="/img/products/options/rivets.jpg" alt="Rivet Options" className="link" onClick={ () => { 
				window.open ('/img/products/options/rivets.jpg', '_blank'); }}/>
		</div>
	</span>,
	saberClips: <span>Saber Clip - We offer two styles of permenantly attached saber clip: one that is 
		narrow and one that is wide.  These are typically used if your lightsaber is equipped with a D-Ring at the end 
		of it.  
		<br /><br />
		<strong>These clips are not movable or removable.</strong>
		<br /><br />If you would like a saber clip fastened to your belt, please let us know which style and which side.  
		Which side the clip is on is in reference to the wearer's side - meaning that "Left" would put it on YOUR left hand side, 
		even though people looking at you would see it on the right.
		<br /><br />
		Wide and Narrow Saber Clips are $15 extra.
		<br /><br />
		If you're considering a saber clip, you should also check out our <a href="/products/saberhangers/dring">D-Ring</a> 
		&nbsp;and <a href="/products/saberhangers/covertec">Covertec</a> Saber Hangers!
		<br /><br />
		<div className="picture-wrapper">
			<img src="/img/products/options/saber-clip.jpg" alt="Saber Clip Options" className="link" onClick={ () => { 
				window.open ('/img/products/options/saber-clip.jpg', '_blank'); }}/>
		</div>
	</span>,
	hangerSize: <span>Size: How wide is the belt you intend to hang this on?  Typically belts are 2, 2.5 or 3" wide.</span>,
	hangerShape: <span>Wide Piece, Bottom Shape: Squared, Angled, or Round?  The bottom of the Saber Hanger can either 
			be square, have its' ends cut at 45 degree angles, or the whole bottom can be round.
			<br /><br />
			<div className="picture-wrapper">
				<img src="/img/products/options/covertec-shapes.jpg" alt="Saber Hanger Shapes" className="link" onClick={ () => { 
					window.open ('/img/products/options/covertec-shapes.jpg', '_blank'); }}/>
			</div>
		</span>,
	hangerDecorations: <span>Wide Piece, Decorations: By default and included in the price, the Wide piece carries Tooling #1, meaning there is a stitching groover line cut around the edge, 1/4" in.  Your second included option is to leave this Wide Piece clean, with no groover line on it. 
			<br /><br />
			For $5 extra we can add hand stitching around the outside edge of the leather in the groover line.  We'll need to know which color thread you want: Black, Brown, Red, White, or Light Blue.
			<br /><br />
			For $5 extra we can add any of the tooling options from the photo can be applied to the Wide piece between the groover line and the outside edge.
			<br /><br />
			Both of these options can be added.
		</span>,
	threadColor: <span>
			Your pouch can be sewn with thread of the following colors: Red, Black, White, Brown (all pictured) or Light Blue.
			<br /><br />
			<div className="picture-wrapper">
				<img src="/img/products/options/stitching.jpg" alt="Pouch Stitching" className="link" onClick={ () => { 
					window.open ('/img/products/options/stitching.jpg', '_blank'); }}/>
			</div>
		</span>,
	pouchClosure: <span>
			Closure:  Your pouch can be closed with a magnet which is invisible when closed and our recommendation.  
			Another option would be to use a silver, black or bronze snap in which case the snap head will be visible 
			when the pouch is closed.
			<br /><br />
			<div className="picture-wrapper">
				<img src="/img/products/options/closures.jpg" alt="Pouch Closures" className="link" onClick={ () => { 
					window.open ('/img/products/options/closures.jpg', '_blank'); }}/>
			</div>
		</span>,
	pouchShape: <span>Pouch Flap Shape: Squared, Angled, or Round?  The pouch flap can either be square, have its' ends 
			cut at 45 degree angles, or the whole bottom can be round.
			<br /><br />
			<div className="picture-wrapper">
				<img src="/img/products/options/flap-shape.jpg" alt="Pouch Shapes" className="link" onClick={ () => { 
					window.open ('/img/products/options/flap-shape.jpg', '_blank'); }}/>
			</div>
		</span>,
	pouchDecorations: <span>Pouch Flap Decorations: By default and included in the price, the pouch flap carries 
		Tooling #1, meaning there is a stitching groover line cut around the edge, 1/4" in.  Your second included 
		option is to leave the flap clean, with no groover line on it. 
		<br /><br />
		<strong>Stamped</strong>: If you choose a magnet closure, then you can optionally add a stamp to the pouch flap 
		for $5 extra.
		<br /><br />
			<div className="picture-wrapper">
				<img src="/img/products/options/stamps.jpg" alt="Pouch Stamps" className="link" onClick={ () => { 
					window.open ('/img/products/options/stamps.jpg', '_blank'); }}/>
			</div>
		<br />
		<strong>Sewn</strong>: On all pouches the side gussets will be sewn on.  For $5 extra we can extend that hand stitching around the outside 
		edge of the pouch flap in the groover line.
		<br /><br />
			<div className="picture-wrapper">
				<img src="/img/products/options/pouch-sewing.jpg" alt="Pouch Sewing" className="link" onClick={ () => { 
					window.open ('/img/products/options/pouch-sewing.jpg', '_blank'); }}/>
			</div>
		<br />
		<strong>Tooled</strong>: For $5 extra we can add any of the tooling options from the photo can be applied to the pouch flap between the 
		groover line and the outside edge.  Below is an example of tooling #3, note the pock mark pattern on the edge.  
		<i>The Jedi Stamp is an example of Stamped, above, and not included in Tooling.</i>
		<br /><br />
			<div className="picture-wrapper">
				<img src="/img/products/options/pouch-tooling.jpg" alt="Pouch Tooling" className="link" onClick={ () => { 
					window.open ('/img/products/options/pouch-tooling.jpg', '_blank'); }}/>
			</div>
		<br />
		See the chart below for tooling options:
		<br /><br />
		<div className="picture-wrapper">
			<img src="/img/products/options/tooling.jpg" alt="Tooling Options" className="link" onClick={ () => { 
			window.open ('/img/products/options/tooling.jpg', '_blank'); }}/>
		</div>
		<br />
		All three of these options, Stamped, Sewn and Tooled can be added to the same pouch.
	</span>,
	
};
/** D-Ring - <video preload="none" poster="https://v-c.etsystatic.com/video/upload/s--GCPf3074--/c_crop,h_720,q_auto,w_720,x_0,y_150/ADB63CAD-C268-404B-A624-F51BD7F532FE_s3cl58.jpg" class="wt-horizontal-center wt-height-full wt-max-width-full" aria-label="Product video"><source src="https://v-c.etsystatic.com/video/upload/s--mivSbbNV--/ac_none,c_crop,du_15,h_720,q_auto:good,w_720,x_0,y_150/ADB63CAD-C268-404B-A624-F51BD7F532FE_s3cl58" type="video/mp4"></video> */
export default options;