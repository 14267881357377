const options = {
	vegTan: <span>** Please note that our products are made from natural veg-tan leather.  
		Being that it is a natural product there may be bumps, lines, pock marks or other small imperfections in the 
		leather as well as variations in how different thicknesses of leather will take dyes and gel.  We do our best to 
		limit these "imperfections" and deliver clean, quality surfaces over all thicknesses and try to use the same 
		source hide for each piece.  Some of these variations aren’t noticeable until color is applied to the item - and 
		at that point it's too costly to make the piece again.  We appreciate your consideration and flexibility when 
		purchasing an item made from a natural source material.</span>,
	mouseHouse: <span>** Please note that all mentions of anything Star Wars related are, of course, 
		the property of Disney and Lucasfilm, all rights to them.</span>,
	handMade: <span>All of our products are hand made from vegetable tanned genuine leather locally sourced in the USA.</span>,
};

export default options;