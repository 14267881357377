import OptionConstants from '../../../constants/options';
import DisclaimerConstants from '../../../constants/disclaimer';
import { Carousel } from 'react-responsive-carousel';

let carouselContent = [
  {
    img: "/img/products/guitarstrap/prequel/1.png",
    caption: "Black, Matte finish, Crimson Red accent."
  },
  {
    img: "/img/products/guitarstrap/prequel/2.png",
    caption: "Red Thread, A1 Rivets."
  },
  {
    img: "/img/products/guitarstrap/prequel/3.png",
    caption: "Covered Rivets surrounding Buckle J."
  },
  { 
    img: "/img/products/guitarstrap/prequel/4.png",
    caption: "Accent Tooling #7"
  },
  { 
    img: "/img/products/guitarstrap/prequel/5.png",
    caption: "Adjustable length."
  },
  { 
    img: "/img/products/guitarstrap/prequel/6.png",
    caption: "Adjustable from 40\" to 54\", but multiple tail lengths are available."
  },
  { 
    img: "/img/products/guitarstrap/prequel/7.png",
    caption: "Strap Tail has 7 connection points to adjust the length."
  },
  { 
    img: "/img/products/guitarstrap/prequel/8.png",
    caption: "Suede backing for comfort and non-slip as you're playing."
  },
];


const Component = () => {
	return [
    <h2 className="title">Prequel Guitar Strap</h2>,
    <p className="content">This Guitar or Bass Guitar strap is based on a a replica of the leather belts worn by many 
      Jedi in the Star Wars Prequel movies, often referred to as Prequel style, worn by Obi Wan Kenobi, 
      Anakin Skywalker, Mace Windu, etc. 
      <br /><br />
      The dimensions of this strap are derived from a guitar strap kit, and have been tested by several professional guitarists.  Ranging from 41.5-54" long there is a lot of adjustment potential in the length.  That said, if you need modifications made please let us know.
    </p>,
    <p className="content">{DisclaimerConstants.handMade}</p>,
    <div className="m-auto pt-3">
      <Carousel 
                autoPlay={true}
                swipable={true}
                stopOnHover={true}
                infiniteLoop={true}
                showThumbs={false}
                >
                  {
                    carouselContent.map(carouselItem => {
                      return <div className="picture-wrapper">
                        <img src={carouselItem.img} alt={carouselItem.caption} />
                        { carouselItem.caption ? <p className="legend">{carouselItem.caption}</p> : null }
                      </div>
                    })
                  }
              </Carousel>
    </div>,
    <p className="content more">{OptionConstants.more}</p>,
    <p className="content option">Pricing: The base price is $119.99.
      <ul>
      <li>Buckles Ni1, Ni8, BB1, and GM1 are included.</li>
      <li>Buckles Ni2, Ni3, Ni6, Ni9, BB4 and BL1 are $25 extra.</li>
      <li>All other Buckles are $15 Extra.</li>
      </ul>
    </p>,
    <p className="content option">
      When ordering a Prequel Guitar Strap we will need to know several things, including:</p>,
    <p className="content option">1. Length - We will need to verify that somewhere in the 41.5-54" range will work for you.
    </p>,
    <p className="content option">2. { OptionConstants.color}</p>,
    <p className="content option">3. { OptionConstants.colorFinish}</p>,
    <p className="content option">4. Suede Lining Color - We can line the back of the strap with Black, Brown or Red Suede.</p>,
    <p className="content option">5. Do you want the main belt and the accent (small) belt to be the same color?  
      If not - what color would you like the accent (small) belt to be?
    </p>,
    <p className="content option">6. Which tooling option would you like your accent belt to have?
      <br /><br />
      See the chart below:
      <br /><br />
      <div className="picture-wrapper">
        <img src="/img/products/options/tooling.jpg" alt="Tooling Options" className="link" onClick={ () => { 
        window.open ('/img/products/options/tooling.jpg', '_blank'); }}/>
      </div>
    </p>,
    <p className="content option">7. Which accent belt buckle would you like?
    <br /><ul>
      <li>Buckles Ni1, Ni8, BB1, and GM1 are included.</li>
      <li>Buckles Ni2, Ni3, Ni6, Ni9, BB4 and BL1 are $25 extra.</li>
      <li>All other Buckles are $15 Extra.</li>
    </ul>
    See the chart below:<br /><br />
      <div className="picture-wrapper">
        <img src="/img/products/options/pj-buckle.jpg" alt="Buckle Options" className="link" onClick={ () => { 
          window.open ('/img/products/options/pj-buckle.jpg', '_blank'); }} />
      </div>
    </p>,
    <p className="content option">8. Accent Belt rivets - There are rivets that hold the accent belt to the main belt 
      in the back at the end of the accent and along the accents length to simulate the chicago screws<br /><br />
      {OptionConstants.rivetColorBody}</p>,
    <p className="content option">9. Rivet Treatment - In the picture below you can see that the top belt has its 
      rivets (on the left and right of the accent belt) exposed, where the bottom two have them wrapped.  These rivets 
      really help the accent belt stay in place and prevent sagging. All of our Prequel Guitar Strap have a riveted accent.  
      Do you want the rivets by the buckle exposed or leather wrapped around them so they look more like traditionally 
      "keeper'd" sections?
      <br /><br />
      <div className="picture-wrapper">
        <img src="/img/products/prequel/1.png" alt="Belts" className="link" onClick={ () => { 
          window.open ('/img/products/prequel/1.png', '_blank'); }} />
      </div>
      <br /><br />
    </p>,
    <p className="content disclaimer">{ DisclaimerConstants.vegTan }</p>,
    <p className="content disclaimer">{ DisclaimerConstants.mouseHouse }</p>,
  ];
};
  
  export default Component;