import React from 'react';
// import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

let carouselContent = [
  {
    img: "img/home/1.png",
    caption: "Prequel Jedi Kit, Belt and Two Pouches"
  },
  {
    img: "img/home/2.png",
    caption: "Family of accessories - Pouches, Covertec and D-Ring"
  },
  {
    img: "img/home/3.png",
    caption: "Prequel Jedi Kit with Aluminum Covertec"
  },
  {
    img: "img/home/4.png",
    caption: "Prequel Sith Kit with D-Ring"
  },
  {
    img: "img/home/5.png",
    caption: "Original Trilogy Sith Kit with Cell Phone Pouch"
  },
  {
    img: "img/home/6.png",
    caption: "Prequel Jedi Belts with accent Tooling"
  },
  {
    img: "img/home/7.png",
    caption: "Sherry, Head Leather Elf"
  }
];

const Home = () => {
	return (
    <div id="homeBody">
      <h2 className="title">Hand made &bull; Genuine Leather &bull; Jedi or Sith</h2>
      <p className="content">Welcome into the shop, we are Valley Green Leather.  Every item we sell is hand made from locally sourced vegetable 
      tanned leather and made with pride.  We are based in Pennsylvania, but we ship our Star Wars Cosplay items world 
      wide through our Etsy shop.</p>
      <div className="m-auto pt-3">
        <Carousel 
          autoPlay={true}
          swipable={true}
          stopOnHover={true}
          infiniteLoop={true}
          showThumbs={false}
          >
            {
              carouselContent.map(carouselItem => {
                return <div className="picture-wrapper">
                  <img src={carouselItem.img} alt={carouselItem.caption} />
                  <p className="legend">{carouselItem.caption}</p>
                </div>
              })
            }
        </Carousel>
      </div>
      <div style={{textAlign: 'center', paddingTop: '10px'}}>
        <button 
          className="btn btn-primary"
          onClick={() => { window.location.href = '/contact'; }}
        >Shop Now!</button></div>
      <p className="content">This site is to walk folks through our process, see more 
        detail about our products, explain the Kit listings, and generally act as a place to bring everything together.  
        Here you'll find details about our pouches, saber hangers, belts and other leather items.</p>
    </div>
    );
  };
  
  export default Home;