import OptionConstants from '../../../constants/options';
import DisclaimerConstants from '../../../constants/disclaimer';
import { Carousel } from 'react-responsive-carousel';

let carouselContent = [
  {
    img: "/img/products/ot/1.png",
    caption: "Black Gloss, A1 Rivets, Narrow Saber Clip - Right, Clean Buckle"
  },
  {
    img: "/img/products/ot/2.png",
    caption: "@TODO AG-X,X Matte?, No Saber Clip, Clean Buckle"
  },
  {
    img: "/img/products/ot/3.png",
    caption: "Wearer's point of view, to show the inside facing loop."
  },
  {
    img: "/img/products/ot/4.png",
    caption: "Black Matte, A1 Rivets, Wide Saber Clip - Right, Clean Buckle"
  },
  {
    img: "/img/products/ot/5.png",
    caption: "AG-12,2 Gloss, No Saber Clip, Clean Buckle, C1 Rivets"
  },
  {
    img: "/img/products/ot/6.png",
    caption: "@TODO AG-X,X Matte, No Saber Clip, Weathered Leather!, Heavy Weathered Buckle, A2 Rivets"
  },
  {
    img: "/img/products/ot/7.png",
    caption: "@TODO AG-X,X Gloss, No Saber Clip, Clean Buckle, D1 Rivets"
  },
];

const OriginalBelt = () => {
	return [
    <h2 className="title">Original Trilogy Belt</h2>,
    <p className="content">This belt is perfect for hanging food-capsules, pouches, lightsaber covertech clips, 
      and anything else your Jedi or Sith Cosplay needs.  Great for other Cosplay Costumes as well!  This style of belt
      is worn by many non-Jedi across the film and TV show franchises.
      <br /><br />
      The buckle is 3" wide and metal.  The belt is 2.5" wide.  These belts have 7 holes punched 
      in them, spaced 1" apart from each other, so ordering a 44" belt will actually give you a belt that fits from a 
      41" to a 47" waist.
      <br /><br />
      The inside facing belt loop is ideal for the appearance of a snug fit.
    </p>,
    <p className="content">{DisclaimerConstants.handMade}</p>,
    <div className="m-auto pt-3">
      <Carousel 
                autoPlay={true}
                swipable={true}
                stopOnHover={true}
                infiniteLoop={true}
                showThumbs={false}
                >
                  {
                    carouselContent.map(carouselItem => {
                      return <div className="picture-wrapper">
                        <img src={carouselItem.img} alt={carouselItem.caption} />
                        { carouselItem.caption ? <p className="legend">{carouselItem.caption}</p> : null }
                      </div>
                    })
                  }
              </Carousel>
    </div>,
    <p className="content more">{OptionConstants.more}</p>,
    <p className="content option">Pricing: The base price is $39.99.
      <ul>
      <li>Wide and Narrow Saber Clips are $15 extra.</li>
      <li>Lengths over 48" long are $10 extra.
      </li>
      </ul>
    </p>,
    <p className="content option">
      When ordering an Original Trilogy Belt we will need to know several things, including:
    </p>,
    <p className="content option">1. {OptionConstants.size}<br /><br />
      We will punch 7 holes in the slack of the belt for adjustability with the center hole the measurement you give us, so it will give you about 3" of play each direction. 
    </p>,
    <p className="content option">2. { OptionConstants.color}</p>,
    <p className="content option">3. { OptionConstants.colorFinish}</p>,
    <p className="content option">4. Buckle rivets - There are rivets that hold the folded leather that holds the buckle on.<br /><br />
      {OptionConstants.rivetColorBody}</p>,
    <p className="content option">5. { OptionConstants.saberClips }
      </p>,
    <p className="content option">6. Buckle - Clean, Lightly Weathered, Heavily Weathered?  Each weathered buckle is done so 
      by hand, so the weathering turns out differently every time.  Please let us know if you're interested in weathering.
      <br /><br />
      <div className="picture-wrapper">
        <img src="/img/products/options/ot-buckle.jpg" alt="Saber Clip Options" className="link" onClick={ () => { 
        window.open ('/img/products/options/ot-buckle.jpg', '_blank'); }}/>
      </div>
      <br /><br />
    </p>,
    <p className="content disclaimer">{ DisclaimerConstants.vegTan }</p>,
    <p className="content disclaimer">{ DisclaimerConstants.mouseHouse }</p>,
    
  ];
};
  
  export default OriginalBelt;