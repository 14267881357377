import OptionConstants from '../../../constants/options';
import DisclaimerConstants from '../../../constants/disclaimer';
import { Carousel } from 'react-responsive-carousel';

let carouselContent = [
  {
    img: "/img/products/pouches/cell/1.png",
    // caption: '@TODO'
  },
  {
    img: "/img/products/pouches/cell/2.png",
    // caption: '@TODO'
  },
  {
    img: "/img/products/pouches/cell/3.png",
    caption: 'Black Gloss, Angled Flap, Red Thread, Stamped with the Sith Empire Logo.'
  },
  {
    img: "/img/products/pouches/cell/4.png",
    caption: 'Open, showing a cell phone inside.'
  },
  {
    img: "/img/products/pouches/cell/5.png",
    caption: 'Side and back profiles.'
  },
  {
    img: "/img/products/pouches/cell/6.png",
    caption: 'Black Matte, Square Flap, Clean, Black Thread.'
  },
  {
    img: "/img/products/pouches/cell/7.png",
    caption: 'AG-10,2 Matte, Rounded Flap, Groover Line, Brown Thread.'
  },
];

const Component = () => {
	return [
    <h2 className="title">Pouch - Cell Phone</h2>,
    <p className="content">Genuine Veg Tan Leather is used to hand craft each and every pouch.  As you 
    can see below there are many options for what goes into your pouch, you can truly customize it 
    because we don't pre-make them.  If there's a pouch option you'd like that isn't listed, please 
    reach out to us and we'll see what we can do!  These pouches make a great addition to a belt 
    purchase, or to any Star Wars Costume you already have in the works.  
    <br /><br />
    Complete your Cosplay today with a pouch that will last a lifetime.
    <br /><br />
    This pouch measures 4 1/8 x 6 3/4 x 7/8" [ 10.5 x 17.1 x 2.2 cm ] on the outside, and can hold 6 3/8th x 3 3/8 x 5/8 [ 16.2 x 8.5 x 1.6 cm ] inside.
    </p>,
    <div className="m-auto pt-3">
      <Carousel 
                autoPlay={true}
                swipable={true}
                stopOnHover={true}
                infiniteLoop={true}
                showThumbs={false}
                >
                  {
                    carouselContent.map(carouselItem => {
                      return <div className="picture-wrapper">
                        <img src={carouselItem.img} alt={carouselItem.caption} />
                        { carouselItem.caption ? <p className="legend">{carouselItem.caption}</p> : null }
                      </div>
                    })
                  }
              </Carousel>
    </div>,
    <p className="content more">{OptionConstants.more}</p>,
    <p className="content option">Pricing: The base price is $34.99.
      <ul>
        <li>A stamp on the flap can be added for $5 extra.</li>
        <li>Stitching around the edge of the leather can be added for $5 extra.</li>
        <li>Tooling around the edge of the leather can be added for $5 extra.</li>
      </ul>
    </p>,
    <p className="content">
    <i><strong>Approval note: </strong>At this time, we have had manys client get Rebel Legion and Saber Build approval 
    with this pouch on their belt.  None of them have had a stamp, but many have had stiching and tooling.</i>
    </p>,
    <p className="content">{DisclaimerConstants.handMade}</p>,
    <p className="content option">When ordering a Pouch we will need to know several things, including:</p>,
    <p className="content option">1. { OptionConstants.color}</p>,
    <p className="content option">2. { OptionConstants.colorFinish}</p>,
    <p className="content option">3. { OptionConstants.threadColor}</p>,
    <p className="content option">4. { OptionConstants.pouchClosure}</p>,
    <p className="content option">5. { OptionConstants.pouchShape}</p>,
    <p className="content option">6. { OptionConstants.pouchDecorations}</p>,
    <p className="content">
      Rebel Legion Status : Clients who purchased this pouch have gotten Rebel Legion approval in costumes which included this pouch with no tooling around the edges of the pouch.  Clean, Stitching Groover Line, Stitched, and Stamped with Jedi Logos have been approved as well as tooling #3, #4, #6, #8, #10, #18, #20.
    </p>,
    <p className="content disclaimer">{ DisclaimerConstants.vegTan }</p>,
    <p className="content disclaimer">{ DisclaimerConstants.mouseHouse }</p>,
  ];
};
  
export default Component;